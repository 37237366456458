<template>
  <VpField label="Mobile Number" name="Mobile Number" v-bind="$attrs">
    <div>
      <VpField rules="required" name="Country Code">
        <VpSelectRequest
          v-model="callingCode"
          :request="callingCodes"
          placeholder="Country Code"
        />
      </VpField>
      <VpField
        :rules="{
          required: true,
          min: MOBILE_MIN_LENGTH,
          max: MOBILE_MAX_LENGTH,
        }"
        name="Mobile Number"
      >
        <VpInput>
          <VpTextBox
            type="number"
            v-model="number"
            placeholder="Mobile Number"
          />
        </VpInput>
      </VpField>
    </div>
  </VpField>
</template>

<script>
import { MOBILE_MAX_LENGTH, MOBILE_MIN_LENGTH } from "static/src/config.json";

import { mapGetters } from "vuex";
import { callingCodes } from "utils/presets";

export default {
  props: {
    callingCodeValue: [String, Number],
    numberValue: [String, Number],
    value: [String, Number],
  },

  watch: {
    callingCode() {
      this.input();
    },
    number() {
      this.input();
    },
  },

  computed: {
    ...mapGetters({
      defaultCallingCode: "biz/callingCode",
    }),
  },

  data() {
    return {
      MOBILE_MAX_LENGTH,
      MOBILE_MIN_LENGTH,
      callingCode: null,
      number: null,
      callingCodes,
    };
  },

  created() {
    this.callingCode = this.callingCodeValue || this.defaultCallingCode;
    this.number = this.numberValue;
  },

  methods: {
    input() {
      this.$emit("update:callingCode", this.callingCode);
      this.$emit("update:number", this.number);
      this.$emit("input", (this.callingCode ?? "") + (this.number ?? ""));
    },
    reset() {
      this.callingCode = this.defaultCallingCode;
      this.number = null;
    },
  },
};
</script>
