<template>
  <AppLayout>
    <div>
      <div
        class="vp-bg-primary-500 vp-h-40 vp-flex vp-items-center vp-justify-center vp-text-white vp-font-bold vp-text-4xl"
      >
        <div class="vp-w-[400px]">🎉 Almost there!</div>
      </div>
      <div class="vp-mt-11 vp-flex vp-justify-center">
        <div>
          <VpVueForm
            :fields="fields"
            :id="null"
            class="vp-p-0 vp-mt-4"
            :save="save"
            :track="$options.track"
            track-prefix="Biz"
            width="400px"
            track-event-name="Setup Completed"
            notify-message="Your business setup is completed. Please wait for a few seconds while we are refreshing the app..."
          >
            <template #default="{ state }">
              <div>
                <h1 class="vp-text-[28px] vp-leading-none vp-font-bold">
                  Let's make business 'play' instead of 'work'!
                </h1>
                <p class="vp-mt-7 vp-text-gray-500 vp-text-base">
                  Welcome to Vepaar! where managing clients is as easy as
                  stealing the last donut!
                </p>
              </div>

              <VpField
                class="vp-mt-8"
                rules="required"
                name="Business name"
                label="Business name"
              >
                <VpInput>
                  <VpTextBox v-model="state.name" />
                </VpInput>
              </VpField>

              <VpField rules="required" name="Country" label="Country">
                <VpSelectRequest
                  :request="$options.countryOptions"
                  v-model="state.countryId"
                />
              </VpField>
            </template>

            <template #footer="{ saveItem, isSaving }">
              <VyButton
                type="button"
                label="Complete"
                :loading="isSaving"
                @click.native="saveItem"
                class="vp-w-full button--primary button--solid button--lg button--rounded"
              />
            </template>
          </VpVueForm>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import create from "./crm-setup.gql";
import { countryOptions } from "utils/presets";
import AppLayout from "@/components/app-layout.vue";
import { getCampaign } from "plugins/utils";

export default {
  countryOptions,
  components: {
    AppLayout,
  },
  track: {
    id: {
      key: "campaign_id",
      value: (id) => id.toString(),
    },
    country: {
      key: "Country",
      value: (country) => country?.name ?? "",
    },
    name: "Name",
  },
  data() {
    return {
      fields: ["name", "countryId"],
    };
  },
  methods: {
    save(id, data) {
      data.countryId = Number(data.countryId);

      return this.$mutate(create, {
        // Onboarding will not have vuex hydration, therefore getting campaignId from cookies instead
        id: getCampaign(),
        input: data,
      }).then(({ data }) => {
        this.$router.push({ name: "home" });

        return data;
      });
    },
  },
};
</script>
