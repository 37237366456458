<template>
  <div class="vp-max-w-3xl">
    <div>
      <TheHeader
        title="Features of Vepaar CRM"
        desc="Amplify WhatsApp web. Vepaar has come up with a good number of features
          that allow you to turn WhatsApp from a typical messaging app into your
          business partner."
      />

      <CardsShell>
        <!-- Funnel -->
        <TheCard
          :icon="$options.icons.Funnel"
          icon-color="pink"
          title="Sales Funnel"
          desc="Turn leads into customers with our elite Sales Funnel feature."
        />

        <TheCard
          :icon="$options.icons.Tag"
          icon-color="cyan"
          title="Tags"
          desc="Organize and manage contacts/messages with tags/labels."
        />

        <TheCard
          icon-color="warning"
          title="Set Customer Profile"
          desc="Record customer info and push leads to CRM."
          :icon="$options.icons.ContactBook"
        />

        <TheCard
          :icon="$options.icons.Document"
          icon-color="danger"
          title="Bulk Import/Export"
          desc="Import or Export contacts and customer data via Excel."
        />

        <TheCard
          :icon="$options.icons.Ticket"
          icon-color="blue"
          title="Ticket Managment"
          desc="Easily manage customer tickets for seamless support."
        />

        <TheCard
          :icon="$options.icons.Chrome"
          icon-color="success"
          title="Chrome Extension"
          desc="Generate leads by extracting contacts and essential data from WhatsApp."
        />
      </CardsShell>
    </div>
  </div>
</template>

<script>
import {
  Funnel,
  Tag,
  ContactBook,
  Ticket,
  Chrome,
  Document,
} from "icons/icons.js";
import TheCard from "components/src/features/_card.vue";
import TheHeader from "components/src/features/_header.vue";
import CardsShell from "components/src/features/_cards-shell.vue";
export default {
  components: {
    TheCard,
    TheHeader,
    CardsShell,
  },
  icons: {
    Funnel,
    Tag,
    ContactBook,
    Ticket,
    Chrome,
    Document,
  },
};
</script>
